import { Link } from "@remix-run/react";
import logoImg from '../assets/logo.png'

export default function Logo() {
	return (
		<Link to="/">
            <img
                src={logoImg}
                alt="Bengine"
                width={300}
                height={30}
            />
		</Link>
	)
}