import { useLocation, useLoaderData, Form, Link, useSubmit } from '@remix-run/react'

import { useRef } from 'react'
import { CartIcon } from '#app/components/cart.tsx'
import { getUserImgSrc } from '../utils/misc.tsx'
import { useOptionalUser, userHasRole } from '../utils/user.ts'
import Logo from './logo.tsx'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuTrigger,
} from './ui/dropdown-menu.tsx'
import { Icon } from './ui/icon.tsx'


function IconUser(props) {
	return (
		<svg
			viewBox="0 0 448 512"
			fill="currentColor"
			height="30"
			width="30"
			{...props}
		>
			<path d="M272 304h-96C78.8 304 0 382.8 0 480c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32 0-97.2-78.8-176-176-176zM48.99 464c7.9-63.1 61.81-112 127.01-112h96c65.16 0 119.1 48.95 127 112H48.99zM224 256c70.69 0 128-57.31 128-128S294.69 0 224 0 96 57.31 96 128c0 70.7 57.3 128 128 128zm0-208c44.11 0 80 35.89 80 80s-35.89 80-80 80-80-35.9-80-80c0-44.11 35.9-80 80-80z" />
		</svg>
	)
}


function UserDropdown() {
	const user = useOptionalUser()
	const submit = useSubmit()
	const formRef = useRef<HTMLFormElement>(null)
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
                <Link
                    to={`/users/${user.username}`}
                    // this is for progressive enhancement
                    onClick={(e) => e.preventDefault()}
                    className="flex items-center gap-2"
                >
                    <img
                        className="h-8 w-8 rounded-full object-cover"
                        alt={user.name ?? user.username}
                        src={getUserImgSrc(user.image?.id)}
                    />
                    {/* <span className="text-body-sm font-bold">
                        {user.name ?? user.username}
                    </span> */}
                </Link>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent sideOffset={8} align="start">
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/users/${user.username}`}>
							<Icon className="text-body-md" name="avatar">
								Profile
							</Icon>
						</Link>
					</DropdownMenuItem>
					{user.orders.length > 0 && (
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/status`}>
							<Icon className="text-body-md" name="file-text">
								Orders
							</Icon>
						</Link>
					</DropdownMenuItem>
					)}
					{userHasRole(user, 'admin') && (
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/admin`}>
							<Icon className="text-body-md" name="pencil-2">
								Admin
							</Icon>
						</Link>
					</DropdownMenuItem>
					)}
					<DropdownMenuItem
						asChild
						// this prevents the menu from closing before the form submission is completed
						onSelect={(event) => {
							event.preventDefault()
							submit(formRef.current)
						}}
					>
						<Form action="/logout" method="POST" ref={formRef}>
							<Icon className="text-body-md" name="exit">
								<button type="submit">Logout</button>
							</Icon>
						</Form>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	)
}

export default function NavBar({ cart }) {
	const user = useOptionalUser()
	const location = useLocation();

    // return (
    //     <header className="container py-6">
    //         <nav className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
    //             <Logo />
    //             {/* <div className="ml-auto hidden max-w-sm flex-1 sm:block">
    //                 {searchBar}
    //             </div> */}
    //             {/* <div className="block w-full sm:hidden">{searchBar}</div> */}
    //         </nav>
    //     </header>
    // )

	function onCartClick() {
		if (location.pathname === '/find-parts') {
			const drawer = document.getElementById('cart-drawer');
			drawer.checked = !drawer.checked;
		} else {
			window.location.href = '/order';
		}
	}

    return (
			<div className="navbar bg-white shadow-md">
				<div className="flex-1 items-center pl-2">
					<Logo />
				</div>
				<div className="flex-none gap-2">
					{cart?.items?.length > 0 && (
						<div className="relative">
							<div className="badge badge-xs badge-accent absolute right-0 top-0">
								{cart.items.reduce((acc, item) => acc + item.quantity, 0)}
							</div>
							<button onClick={onCartClick} className="btn btn-circle btn-ghost">
								<CartIcon />
							</button>
						</div>
					)}
					{/* <button className="btn btn-circle btn-ghost">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-8 w-8"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
							/>
						</svg>
					</button> */}
					<div className="dropdown dropdown-end">
						<div
							tabIndex={0}
							role="button"
                            className="btn btn-circle btn-ghost"
						>
                            {user ? (
                                <UserDropdown />
                            ) : (
                                <Link to="/login"><IconUser /></Link>
                            )}
						</div>
					</div>
				</div>
			</div>
		)
}